import Vue from 'vue'
import Router from 'vue-router'
import SNRPage from './../components/SNRPage.vue'

Vue.use(Router)

export default new Router({
    routes: [
      { path: '/calls/:call1/:call2/band/:band', component: SNRPage },
      { path: '/', component: SNRPage },
    ]
})