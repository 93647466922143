<template>
  <div id="SNRPage">
    <h4>
      Specify two callsigns and see how they heard each other in the last 24
      hours.<br />
      All data coming from wspr.live DB
    </h4>

    First Callsign:
    <input
      type="text"
      v-model="callsign1"
      v-on:keyup.enter="getWSPRData"
      v-uppercase
    />
    Second Callsign:
    <input
      type="text"
      v-model="callsign2"
      v-on:keyup.enter="getWSPRData"
      v-uppercase
    />

    <div id="v-model-select-dynamic" class="demo">
      Band:
      <select v-model="selected_band">
        <option
          v-for="option in band_options"
          :value="option.value"
          :key="option.name"
        >
          {{ option.text }}
        </option>
      </select>
    </div>

    <label for="normalize_snr">Normalize SNR to 5W TX power</label>
    <input
      type="checkbox"
      id="normalize_snr"
      v-model="normalize_snr"
      @change="normalizeCheckboxChanged"
    />

    <br />
    <button @click="getWSPRData">Get data</button>
    <line-chart
      v-if="loaded"
      :plot1ChartData="plot1ChartData"
      :plot1Title="plot1Title"
      :plot2ChartData="plot2ChartData"
      :plot2Title="plot2Title"
    ></line-chart>
  </div>
</template>

<script>
import LineChart from "@/components/LineChart.vue";

export default {
  name: "SNRPage",

  components: {
    LineChart,
  },
  mounted() {
    console.log("Params:" + JSON.stringify(this.$route.params));

    if (
      this.$route.params.call1 &&
      this.$route.params.call2 &&
      this.$route.params.band
    ) {
      this.callsign1 = this.$route.params.call1;
      this.callsign2 = this.$route.params.call2;
      this.selected_band = this.$route.params.band;
      this.getWSPRData();
    }
  },
  data() {
    return {
      loaded: false,
      normalize_snr: false,
      callsign1: "",
      callsign2: "",
      selected_band: 3,
      band_options: [
        { text: "2200m", value: -1 },
        { text: "630m", value: 0 },
        { text: "160m", value: 1 },
        { text: "80m", value: 3 },
        { text: "60m", value: 5 },
        { text: "40m", value: 7 },
        { text: "30m", value: 10 },
        { text: "20m", value: 14 },
        { text: "17m", value: 18 },
        { text: "15m", value: 21 },
        { text: "12m", value: 24 },
        { text: "10m", value: 28 },
        { text: "6m", value: 50 },
        { text: "4m", value: 70 },
        { text: "2m", value: 144 },
        { text: "70cm", value: 432 },
        { text: "23cm", value: 1296 },
      ],
      plot1ChartData: [],
      plot1Title: "",
      plot2ChartData: [],
      plot2Title: "",
      wsprData1: [],
      wsprData2: [],
    };
  },
  methods: {
    normalizeCheckboxChanged() {
      if (this.loaded) {
        this.plotData();
      }
    },
    async getCallData(callsign1, callsign2) {
      const queryString =
        "select	* from wspr.rx where time > subtractHours(now(), 24)" +
        " and tx_sign = '" +
        callsign1 +
        "'" +
        " and rx_sign = '" +
        callsign2 +
        "'" +
        " and band = " +
        this.selected_band +
        " order by time format JSON";

      console.log("Query string:" + queryString);

      const response = await fetch(
        "https://db1.wspr.live/?query=" + queryString
      );
      const responseData = await response.json();
      let returnArray = [];

      //need to filter out duplicates, because sometimes the same callsign is received multiple times with different SNRs
      responseData.data.forEach((element) => {
        let foundItem = returnArray.find((e) => e.time === element.time);
        if (foundItem) {
          // console.log(foundItem);
          //if the SNR for the found item is smaller than the current, we update the found item, otherwise we simply ignore it
          if (foundItem.snr < element.snr) {
            foundItem.snr = element.snr;
          }
        } else {
          returnArray.push(element);
        }
      });

      return returnArray;
    },

    async fetchData() {
      this.wsprData1 = await this.getCallData(this.callsign1, this.callsign2);

      this.wsprData2 = await this.getCallData(this.callsign2, this.callsign1);
    },

    plotData() {
      this.loaded = false;
      this.plot1ChartData = [];
      this.plot1ChartData = this.wsprData1.map((singleReport) => {
        this.normalizedSNR(singleReport);
        let obj = {};
        obj.x = new Date(singleReport.time.replace(/-/g, "/"));
        obj.y = this.normalizedSNR(singleReport);
        return obj;
      });
      this.plot2ChartData = [];
      this.plot2ChartData = this.wsprData2.map((singleReport) => {
        let obj = {};
        obj.x = new Date(singleReport.time.replace(/-/g, "/"));
        obj.y = this.normalizedSNR(singleReport);
        return obj;
      });

      this.plot1Title = this.callsign2 + " hearing " + this.callsign1;
      this.plot2Title = this.callsign1 + " hearing " + this.callsign2;
      this.loaded = true;
    },

    normalizedSNR(element) {
      const referencePower = 37;
      let returnValue = element.snr;
      //if the trasmit power is not 5W, but normalisation is turned on...
      if (this.normalize_snr && element.power != referencePower) {
        const diff = element.power - referencePower;
        console.log("Power difference: " + diff);
        if (diff <= 0) {
          returnValue = element.snr + Math.abs(diff);
        } else {
          returnValue = element.snr - Math.abs(diff);
        }
      }
      return returnValue;
    },

    async getWSPRData() {
      this.loaded = false;
      this.callsign1 = this.callsign1.trim();
      this.callsign2 = this.callsign2.trim();

      this.setURL();

      await this.fetchData();
      this.plotData();
    },

    setURL() {
      history.pushState(
        {
          call1: this.callsign1,
          call2: this.callsign2,
          band: this.selected_band,
        },
        "WSPR SNR",
        `/#/calls/${this.callsign1}/${this.callsign2}/band/${this.selected_band}`
      );
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
